import { CMSEvent } from '../../redux/modules/event/interfaces';
import { useIntl } from 'react-intl';

export const useEventLocation = (address: CMSEvent['address'], place: CMSEvent['information']['place']): string => {
  const intl = useIntl();

  if (address) {
    const { city, state } = address;
    return [city, state].filter(Boolean).join(', ');
  }

  if (place) {
    const { locality, ISOCode3Letter } = place;
    const country = ISOCode3Letter ? intl.formatMessage({ id: `countries.${ISOCode3Letter.toLowerCase()}` }) : '';
    return [locality, country].filter(Boolean).join(', ');
  }

  return '';
};
