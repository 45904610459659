import { helpers } from '@components';
import styled from 'styled-components';

export default {
  Wrapper: styled.span`
    cursor: pointer;
    :hover {
      p {
        color: ${props => props.theme.colors.primary};
      }
    }
    ${helpers}
  `
};
