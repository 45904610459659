import { AppTheme, SVGIcon } from '@components';
import React, { FC } from 'react';
import TextIcon, { TextIconProps } from '../../TextIcon';

type EventPlace = {
  place: string;
  textIconColor: keyof AppTheme['colors']['text'];
  iconSize?: string;
  textSize?: string;
  iconSrc?: string;
} & TextIconProps;

export const EventPlace: FC<EventPlace> = ({
  place,
  textIconColor,
  iconSize = '16px',
  textSize = '',
  iconSrc = '/static/icons/pinLight.svg',
  ...rest
}) => {
  return (
    <TextIcon
      color={textIconColor}
      leftIconComponent={() => <SVGIcon src={iconSrc} size={iconSize} />}
      size={textSize}
      {...rest}
    >
      {place}
    </TextIcon>
  );
};
