import Link from 'next/link';
import React, { FC } from 'react';
import LocalizedText from 'src/components/LocalizedText';
import Breakpoints from '../Breakpoints';
import TextIcon from '../TextIcon';
import ArrowLinkStyles from './styles';

type ArrowLinkProps = {
  href: string;
  labelId: string;
  description: string;
};

const ArrowLink: FC<ArrowLinkProps> = ({ href, labelId, description }) => (
  <ArrowLinkStyles.Link>
    <Link href={href}>
      <a>
        <TextIcon
          shouldHaveHover
          weight="bold"
          rightIconComponent={active => <img src={`/static/icons/semiArrowRightTiny${active ? 'Primary' : ''}.svg`} />}
        >
          <Breakpoints.Mobile>
            <LocalizedText id={labelId} description={description} />
          </Breakpoints.Mobile>
          <Breakpoints.TabletAndUp>
            <LocalizedText id={labelId} description={description} />
          </Breakpoints.TabletAndUp>
        </TextIcon>
      </a>
    </Link>
  </ArrowLinkStyles.Link>
);

export default ArrowLink;
