import { HelperProps, helpers, Scrollbar, Tile } from '@components';
import { LaptopAndUp, TabletAndUp } from 'src/components/stylingUtils';
import styled from 'styled-components';

export default {
  Container: styled.div<HelperProps>`
    ${helpers}

    ${props => TabletAndUp(props)} {
      > ${Tile} {
        padding: ${props => props.theme.spacing.sm};
        box-sizing: border-box;
      }
    }
  `,
  SpacedList: styled.div`
    /* For every element inside this component, but only the first nested */
    > * {
      /* If not the last one */
      &:not(:last-child) {
        margin-bottom: ${props => props.theme.spacing.lgxx};
      }
    }
    padding: ${props => props.theme.spacing.md};
    max-height: 763px;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;

    ${Scrollbar};
  `,
  Filter: styled.span`
    cursor: pointer;
    :hover {
      p {
        color: ${props => props.theme.colors.primary};
      }
    }
    ${helpers}
  `,
  EventWidth: styled.div`
    background-color: #ffffff;
    display: inline-block;
    width: 80vw;
    height: 100%;

    > * {
      height: 100%;
    }

    :not(:last-child) {
      margin-right: ${props => props.theme.spacing.md};
    }

    ${props => TabletAndUp(props)} {
      width: 100%;
      :not(:last-child) {
        margin-right: initial;
      }
    }
  `,
  TitleSpacing: styled.div`
    margin-bottom: ${props => props.theme.spacing.sm};

    ${props => TabletAndUp(props)} {
      margin-bottom: ${props => props.theme.spacing.default};
    }

    ${props => LaptopAndUp(props)} {
      margin-bottom: ${props => props.theme.spacing.md};
    }
  `
};
