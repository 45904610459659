import parse from 'html-react-parser';
import moment from 'moment';
import Link from 'next/link';
import React, { FC, useRef, useEffect, useState } from 'react';
import LocalizedText from 'src/components/LocalizedText';
import { useLocale } from '../../../locales';
import { CMSEvent } from '../../../redux/modules/event/interfaces';
import { Flex, Loader, Text } from '@components';
import TextIcon from '../../TextIcon';
import { EventPlace } from '../EventPlace';
import { useEventLocation } from '../use-event-location';
import Styles from './styles';
import { useWindowWidth } from '../../../utils/viewportUtils';

export const formatDate = (format: string, a: moment.Moment, b?: moment.Moment) => {
  const first = a.format(format);
  const second = b && b.format(format);

  if (!second || first === second) {
    return first;
  }

  return `${first}–${second}`;
};

const convertDate = (date: CMSEvent['dates'][0]) => {
  const start = moment(date.start);
  const end = date.end && moment(date.end);

  return {
    day: formatDate('DD', start, end),
    month: formatDate('MMMM', start, end),
    year: formatDate('YYYY', start, end)
  };
};

const EventDate: FC<{ dates: CMSEvent['dates'] }> = ({ dates }) => {
  if (dates.length === 0) {
    return <Styles.DateContainer />;
  }

  const { day, month, year } = convertDate(dates[0]);

  return (
    <Styles.DateContainer>
      <Styles.DateHead>{year}</Styles.DateHead>
      <Styles.DateBody>
        <Text.Paragraph weight="bold" size="36px">
          {day}
        </Text.Paragraph>
        <Text.SmallParagraph align="center">{month}</Text.SmallParagraph>
        {dates.length > 1 && (
          <Text.Caption paddingTop="sm" align="center">
            <LocalizedText id="events.multipleDates" description="Carried out several times" />
          </Text.Caption>
        )}
      </Styles.DateBody>
    </Styles.DateContainer>
  );
};

const EventTeaserLoading: FC = () => (
  <Flex mobile gap="default" padding="md">
    <Styles.AsideContainer>
      <Styles.DateContainer>
        <Loader fullWidth paddingVertical="xxlg" />
      </Styles.DateContainer>
    </Styles.AsideContainer>
    <Flex mobile fullWidth vertical>
      <Text.H4 marginBottom="sm" fullWidth>
        <Loader width="70%" paddingVertical="sm" considerSpacing />
      </Text.H4>
      <Text.SmallerParagraph color="onWhiteLight" marginBottom="xsm" fullWidth>
        <Loader paddingVertical="xsm" considerSpacing width="80%" />
      </Text.SmallerParagraph>
      <Text.SmallerParagraph color="onWhiteLight" marginBottom="xsm" fullWidth>
        <Loader paddingVertical="xsm" considerSpacing width="95%" />
      </Text.SmallerParagraph>
      <Text.SmallerParagraph color="onWhiteLight" marginBottom="xsm" fullWidth>
        <Loader paddingVertical="xsm" considerSpacing width="60%" />
      </Text.SmallerParagraph>
    </Flex>
  </Flex>
);

type EventTeaserProps = {
  event?: CMSEvent;
  loading?: boolean;
};
const EventTeaser: FC<EventTeaserProps> = ({ event, loading = false }) => {
  const locale = useLocale();
  const leadTextRef = useRef(null);
  const width = useWindowWidth();
  const [leadTextIsTruncated, setLeadTextIsTruncated] = useState(false);
  const [leadTextIsOpen, setLeadTextIsOpen] = useState(false);

  useEffect(() => {
    const el: any = leadTextRef.current;
    setLeadTextIsTruncated(!!el && el.scrollHeight > el.clientHeight);
  }, [leadTextRef, width, leadTextIsOpen]);

  useEffect(() => {
    setLeadTextIsOpen(false);
  }, [width]);

  const toggleLeadTextIsOpen = () => {
    if (leadTextIsOpen) {
      setLeadTextIsTruncated(true);
    }
    setLeadTextIsOpen(!leadTextIsOpen);
  };

  if (loading || !event) {
    return <EventTeaserLoading />;
  }

  const {
    dates,
    address,
    title,
    leadText,
    drupalNodeId: slug,
    image,
    information: { place }
  } = event;
  const eventLink = `/${locale}/event/${slug}`;
  const eventPlace = useEventLocation(address, place);
  const truncantedLeadTextNumberOfLines = 4;

  return (
    <Styles.EventTeaserContainer mobile>
      <Link href={eventLink}>
        <Styles.AsideContainer data-analytics-id="41-clicks-events-bild">
          <Flex padding="md">
            <EventDate dates={dates} />
          </Flex>
          <Styles.ImageContainer data-transform="event-image" src={image && image.teaserMd} />
        </Styles.AsideContainer>
      </Link>

      <Flex vertical gap="sm" padding="md">
        <Styles.EventTitle>
          <Link href={eventLink}>
            <a>{title}</a>
          </Link>
        </Styles.EventTitle>
        {eventPlace && <EventPlace place={eventPlace} textIconColor="onWhiteLight" />}
        {leadText && (
          <Text.SmallerParagraph>
            <Text.Div
              color="onWhiteLight"
              wordBreak="break-word"
              numberOfLines={leadTextIsOpen ? 0 : truncantedLeadTextNumberOfLines}
              ref={leadTextRef}
            >
              <span>{parse(leadText)}</span>
            </Text.Div>
            {(leadTextIsTruncated || leadTextIsOpen) && (
              <Text.VisualLink onClick={() => toggleLeadTextIsOpen()}>
                <LocalizedText id={leadTextIsOpen ? 'showLess' : 'showMore'} description="See more button" />
              </Text.VisualLink>
            )}
          </Text.SmallerParagraph>
        )}
        <Link href={eventLink}>
          <Styles.Click data-analytics-id="41-click-events-link">
            <TextIcon
              shouldUnderlineOnHover
              variant="Tiny"
              size="13px"
              weight="bold"
              color="onWhitePrimary"
              marginRight="default"
              spacing="none"
              rightIconComponent={() => <img src="/static/icons/semiArrowRightTinyPrimary.svg" />}
            >
              <LocalizedText id="seeMore" description="See more button" />
            </TextIcon>
          </Styles.Click>
        </Link>
      </Flex>
    </Styles.EventTeaserContainer>
  );
};

export default EventTeaser;
