import { Flex, Text } from '@components';
import React, { Dispatch, FC, SetStateAction } from 'react';
import LocalizedText from 'src/components/LocalizedText';
import FilterStyles from './styles';

export enum FilterTypes {
  all,
  relevant,
  myUpcoming
}

export type Filter = {
  type: FilterTypes;
  onClick: () => void;
  label: {
    id: string;
    count: number;
  };
};

type EventFiltersProps = {
  filters: Filter[];
  selectedFilter?: FilterTypes;
};

export const EventFilters: FC<EventFiltersProps> = ({ filters, selectedFilter }) => (
  <Flex $wrap data-test-id="event-filters">
    {filters.length > 1 &&
      filters.map(({ type, onClick, label: { id, count } }) => (
        <FilterStyles.Wrapper key={type}>
          <Text.SmallerParagraph
            align="center"
            paddingRight="default"
            onClick={onClick}
            weight="bold"
            color={selectedFilter === type ? 'onWhitePrimary' : undefined}
            data-analytics-id="42-click-event-filter"
          >
            <LocalizedText id={id} values={{ count }} description={`Events filter: ${type}`} />
          </Text.SmallerParagraph>
        </FilterStyles.Wrapper>
      ))}
  </Flex>
);

export const getEventFilters = (
  setFilter: Dispatch<SetStateAction<FilterTypes>>,
  relevantUserEventsCount: number,
  allEventsCount: number,
  myUpcomingEventsCount = 0
): Filter[] => {
  const filters: Filter[] = [];

  if (myUpcomingEventsCount > 0) {
    filters.push({
      type: FilterTypes.myUpcoming,
      label: { id: 'events.filters.myUpcomingEvents', count: myUpcomingEventsCount },
      onClick: () => setFilter(FilterTypes.myUpcoming)
    });
  }

  if (relevantUserEventsCount > 0) {
    filters.push({
      type: FilterTypes.relevant,
      label: { id: 'events.filters.relevantEvents', count: relevantUserEventsCount },
      onClick: () => setFilter(FilterTypes.relevant)
    });
  }

  if (allEventsCount > 0) {
    filters.push({
      type: FilterTypes.all,
      label: { id: 'events.filters.allEvents', count: allEventsCount },
      onClick: () => setFilter(FilterTypes.all)
    });
  }

  return filters;
};
