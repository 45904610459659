import React, { FC } from 'react';
import LocalizedText from 'src/components/LocalizedText';
import ArrowLink from 'src/components/ArrowLink';
import { RequestResult } from 'src/interfaces/requests';
import { CMSEvent } from 'src/redux/modules/event/interfaces';
import { useLocale } from '../../../locales';
import BlankState from '../../BlankState';
import HorizontalScroll from '../../HorizontalScroll';
import { EventFilters, Filter, FilterTypes } from '../EventFilters';
import EventTeaser from '../EventTeaser';
import UpcomingEventsStyles from './styles';
import { Breakpoints, Container, ContainerPadding, Flex, Text, Tile } from '@components';

type UpcomingEventsProps = {
  events: CMSEvent[];
  loading: boolean;
  error?: RequestResult;
  tocGutter?: boolean;
  hideDashboardUpcomingEvents?: boolean;
  selectedFilter?: FilterTypes;
  filters?: Filter[];
};

const UpcomingEvents: FC<UpcomingEventsProps> = ({
  events,
  loading,
  tocGutter,
  hideDashboardUpcomingEvents,
  selectedFilter,
  filters = []
}) => {
  const locale = useLocale();
  const eventsLink = `/${locale}/events`;

  return (
    <UpcomingEventsStyles.Container>
      <Container tocGutter={tocGutter}>
        <UpcomingEventsStyles.TitleSpacing>
          <Breakpoints.Mobile>
            <Flex vertical gap>
              <Flex justifyContent="space-between" alignItems="center" gap>
                <Text.H4Responsive>
                  <LocalizedText
                    id={hideDashboardUpcomingEvents ? 'markets.upcomingEvents' : 'dashboard.upcomingEvents'}
                    description="Upcoming events section title"
                  />
                </Text.H4Responsive>
                <ArrowLink href={eventsLink} labelId="contentEventsLink" description="All events link" />
              </Flex>
              <EventFilters filters={filters} selectedFilter={selectedFilter} />
            </Flex>
          </Breakpoints.Mobile>
          <Breakpoints.TabletAndUp>
            <Flex justifyContent="space-between" alignItems="center" gap>
              <Text.H4Responsive>
                <LocalizedText
                  id={hideDashboardUpcomingEvents ? 'markets.upcomingEvents' : 'dashboard.upcomingEvents'}
                  description="Upcoming events section title"
                />
              </Text.H4Responsive>
              <Flex justifyContent="space-between" alignItems="center">
                <EventFilters filters={filters} selectedFilter={selectedFilter} />
                <ArrowLink href={eventsLink} labelId="contentEventsLink" description="All events link" />
              </Flex>
            </Flex>
          </Breakpoints.TabletAndUp>
        </UpcomingEventsStyles.TitleSpacing>
      </Container>

      {loading ? (
        <>
          <Breakpoints.TabletAndUp>
            <Container tocGutter={tocGutter}>
              <Tile>
                <UpcomingEventsStyles.SpacedList>
                  {Array(3)
                    .fill(3)
                    .map((_, index) => (
                      <UpcomingEventsStyles.EventWidth key={index}>
                        <EventTeaser loading />
                      </UpcomingEventsStyles.EventWidth>
                    ))}
                </UpcomingEventsStyles.SpacedList>
              </Tile>
            </Container>
          </Breakpoints.TabletAndUp>
          <Breakpoints.Mobile>
            <HorizontalScroll hideArrowsMobile scrollContentTravelDistance={420}>
              <ContainerPadding tocGutter={tocGutter}>
                {Array(3)
                  .fill(3)
                  .map((_, index) => (
                    <UpcomingEventsStyles.EventWidth key={index}>
                      <EventTeaser loading />
                    </UpcomingEventsStyles.EventWidth>
                  ))}
              </ContainerPadding>
            </HorizontalScroll>
          </Breakpoints.Mobile>
        </>
      ) : events.length > 0 ? (
        <>
          <Breakpoints.TabletAndUp>
            <Container tocGutter={tocGutter}>
              <Tile>
                <UpcomingEventsStyles.SpacedList>
                  {events.map(event => (
                    <UpcomingEventsStyles.EventWidth key={event.drupalNodeId}>
                      <EventTeaser event={event} />
                    </UpcomingEventsStyles.EventWidth>
                  ))}
                </UpcomingEventsStyles.SpacedList>
              </Tile>
            </Container>
          </Breakpoints.TabletAndUp>
          <Breakpoints.Mobile>
            <HorizontalScroll hideArrowsMobile scrollContentTravelDistance={420}>
              <ContainerPadding tocGutter={tocGutter}>
                {events.map(event => (
                  <UpcomingEventsStyles.EventWidth key={event.drupalNodeId}>
                    <EventTeaser event={event} />
                  </UpcomingEventsStyles.EventWidth>
                ))}
              </ContainerPadding>
            </HorizontalScroll>
          </Breakpoints.Mobile>
        </>
      ) : (
        <Container tocGutter={tocGutter}>
          <BlankState icon={<img src="/static/icons/listLight.svg" />}>
            <LocalizedText
              id={hideDashboardUpcomingEvents ? 'blankState.markets.events' : 'blankState.dashboard.events'}
              description="Latest content"
            />
          </BlankState>
        </Container>
      )}
    </UpcomingEventsStyles.Container>
  );
};

export default UpcomingEvents;
