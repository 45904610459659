import React, { useEffect, useState } from 'react';

/**
 * Calculates the minimum height for the children elements so that they have the same height
 * @param {Object} ref - reference to the parent element
 * @param {string} selector - selector for the children elements
 * @returns {number} minimum content height
 */
const useAlignHeadersHeight = (ref: React.MutableRefObject<null>, selector: string) => {
  const [minHeaderHeight, setMinHeaderHeight] = useState(0);

  useEffect(() => {
    if (ref === null || ref.current === null) return;

    const current = (ref.current as unknown) as Element;
    const children = Array.from(current.querySelectorAll(selector));
    const minChildrenHeaderHeight = children.reduce((acc, val) => Math.max(acc, val.clientHeight), 0);

    setMinHeaderHeight(minChildrenHeaderHeight);
  }, [ref?.current]);

  return minHeaderHeight;
};

export default useAlignHeadersHeight;
