import { Flex, Text } from '@components';
import styled from 'styled-components';

export default {
  EventTeaserContainer: styled(Flex)`
    &:hover {
      ${'[data-transform="event-image"]'} {
        transform: scale(1.05);
      }
    }
  `,
  AsideContainer: styled.a`
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    min-width: 214px;
    min-height: 194px;
    ${({ onClick }) => (onClick ? 'cursor:pointer;' : '')}
  `,
  ImageContainer: styled.div<{ src?: string | null }>`
    ${({ src }) =>
      src
        ? `
    background-image: url('${src}');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;`
        : ''}
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 0;
    transition: transform 0.45s cubic-bezier(0.33, 0.7, 0.32, 1) 0ms;
  `,
  DateContainer: styled(Flex).attrs({ vertical: true })`
    min-width: 150px;
    max-width: 150px;
    z-index: 1;
  `,
  DateHead: styled.div`
    width: 100%;
    text-align: center;
    padding: ${({ theme }) => theme.spacing.xsm} 0;
    color: ${({ theme }) => theme.colors.text.onPrimary};
    background-color: ${({ theme }) => theme.colors.primary};
    border-top-left-radius: ${({ theme }) => theme.spacing.xxsm};
    border-top-right-radius: ${({ theme }) => theme.spacing.xxsm};
  `,
  DateBody: styled(Flex).attrs({ vertical: true, alignItems: 'center', paddingVertical: 'sm', gap: 'xsm' })`
    width: 100%;
    background-color: ${props => props.theme.colors.background};
    border-bottom-left-radius: ${({ theme }) => theme.spacing.xxsm};
    border-bottom-right-radius: ${({ theme }) => theme.spacing.xxsm};
  `,
  EventTitle: styled(Text.H4)`
    & > a {
      display: block;
      text-decoration: none;
    }
    &:hover {
      cursor: pointer;
    }
  `,
  Click: styled.a`
    text-decoration: none;
    ${props => (props.onClick ? 'cursor:pointer;' : '')}
  `
};
