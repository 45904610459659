import { helpers } from '@components';
import { LaptopAndUp, TabletAndUp } from 'src/components/stylingUtils';
import styled from 'styled-components';

export default {
  Container: styled.div`
    ${helpers}
  `,
  Filter: styled.span`
    cursor: pointer;
    ${helpers}
  `,
  Title: styled.div`
    > * {
      word-wrap: pre;
    }
  `,
  ArticleWidth: styled.div`
    display: inline-block;
    width: 323px;
    height: 100%;

    > * {
      height: 100%;
    }

    :not(:last-child) {
      margin-right: ${props => props.theme.spacing.xsm};
    }

    ${props => TabletAndUp(props)} {
      width: 376px;
      :not(:last-child) {
        margin-right: ${props => props.theme.spacing.md};
      }
    }

    ${props => LaptopAndUp(props)} {
      width: 372px;
    }
  `,
  TitleSpacing: styled.div`
    margin-bottom: ${props => props.theme.spacing.sm};

    ${props => TabletAndUp(props)} {
      margin-bottom: ${props => props.theme.spacing.default};
    }

    ${props => LaptopAndUp(props)} {
      margin-bottom: ${props => props.theme.spacing.md};
    }
  `
};
