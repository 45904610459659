import React from 'react';
import parse from 'html-react-parser';
import ArticleTeaserStyles from './styles';
import LocalizedText from 'src/components/LocalizedText';
import Card from '../../Card';
import { useLocale } from '../../../locales';
import { CMSArticle } from '../../../redux/modules/article/interfaces';
import Link from 'next/link';
import truncate from 'lodash/truncate';
import { Flex, Loader, Text, TextIcon } from '@components';

type ArticleTeaserProps = {
  article?: CMSArticle;
  loading?: boolean;
};

const ArticleTeaserLoading = () => (
  <ArticleTeaserStyles.Container>
    <Card full loading>
      <Text.H4 marginBottom="sm">
        <Loader width="60%" padding="sm" considerSpacing />
      </Text.H4>
      <Text.SmallParagraph>
        <Loader width="100%" padding="lg" considerSpacing />
      </Text.SmallParagraph>
    </Card>
  </ArticleTeaserStyles.Container>
);

const MAX_LEAD_TEXT_LENGTH = 160;

const ArticleTeaser: React.FC<ArticleTeaserProps> = ({ article, loading }) => {
  const locale = useLocale();

  if (loading || !article) {
    return <ArticleTeaserLoading />;
  }

  const { title, leadText, image, drupalNodeId } = article;
  const articleLink = `/${locale}/article/${drupalNodeId}`;

  return (
    <ArticleTeaserStyles.Container>
      <Card
        imageSrc={image?.teaserMd}
        spacing="xmd"
        imageWrapper={component => (
          <Link href={articleLink}>
            <a>{component}</a>
          </Link>
        )}
        full
      >
        <ArticleTeaserStyles.Title>
          <Text.H4>{title}</Text.H4>
        </ArticleTeaserStyles.Title>

        <ArticleTeaserStyles.DescriptionContainer>
          <Text.SmallParagraph color="onWhiteLight">
            {leadText && parse(truncate(leadText, { length: MAX_LEAD_TEXT_LENGTH }))}
          </Text.SmallParagraph>
        </ArticleTeaserStyles.DescriptionContainer>

        <ArticleTeaserStyles.Click>
          <Flex alignItems="center" justifyContent="space-between">
            <Link href={articleLink}>
              <a>
                <TextIcon
                  shouldUnderlineOnHover
                  variant="Tiny"
                  size="13px"
                  weight="bold"
                  color="onWhitePrimary"
                  spacing="none"
                  rightIconComponent={() => <img src="/static/icons/semiArrowRightTinyPrimary.svg" />}
                >
                  <LocalizedText id="readMore" description="readMore button" />
                </TextIcon>
              </a>
            </Link>
          </Flex>
        </ArticleTeaserStyles.Click>
      </Card>
    </ArticleTeaserStyles.Container>
  );
};

export default ArticleTeaser;
