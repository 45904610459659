import styled from 'styled-components';
import { LaptopAndUp, DesktopAndUp } from 'src/components/stylingUtils';
import Card from '../../Card/styles';

export default {
  Container: styled.div`
    /* Mobile */
    /* Mobile has 4 columns, so 4 = fullwidth */
    grid-column: span 4;

    /* Tablet */
    @media (min-width: ${props => props.theme.breakpoints.small}) {
      /* Tablet has 8 columns, so 8 = fullwidth */
      grid-column: span 4;
    }

    /* Laptop */
    @media (min-width: ${props => props.theme.breakpoints.large}) {
      grid-column: span 4;
    }

    a {
      text-decoration: none;
    }

    ${Card.ContentContainer} {
      padding: ${props => props.theme.spacing.lg};

      ${props => LaptopAndUp(props)} {
        padding: ${props => props.theme.spacing.md};
      }

      ${props => DesktopAndUp(props)} {
        padding: ${props => props.theme.spacing.lg};
      }
    }
  `,
  DescriptionContainer: styled.div`
    margin-bottom: ${props => props.theme.spacing.md};

    ${props => LaptopAndUp(props)} {
      margin-bottom: ${props => props.theme.spacing.none};
    }

    ${props => DesktopAndUp(props)} {
      margin-bottom: ${props => props.theme.spacing.md};
    }
  `,
  Click: styled.div`
    ${props => (props.onClick ? 'cursor:pointer;' : '')}
  `,
  Title: styled.div`
    margin-bottom: ${props => props.theme.spacing.sm};

    ${props => LaptopAndUp(props)} {
      margin-bottom: ${props => props.theme.spacing.xxsm};
    }

    ${props => DesktopAndUp(props)} {
      margin-bottom: ${props => props.theme.spacing.sm};
    }
  `
};
