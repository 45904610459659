import React from 'react';
import LocalizedText from 'src/components/LocalizedText';
import ArrowLink from '../../../components/ArrowLink';
import { RequestResult } from '../../../interfaces/requests';
import { CMSArticle } from '../../../redux/modules/article/interfaces';
import Breakpoints from '../../Breakpoints';
import { useLocale } from '../../../locales';
import BlankState from '../../BlankState';
import HorizontalScroll from '../../HorizontalScroll';
import ArticleTeaser from '../ArticleTeaser';
import LatestContentStyles from './styles';
import { Container, ContainerPadding, Flex, Grid, Text } from '@components';

type LatestContentProps = {
  articles: CMSArticle[];
  loading: boolean;
  error?: RequestResult;
  doRetrieveLatestContent: () => void;
  articlesSlug?: string;
  tocGutter?: boolean;
  hideDashboardLatestContent?: boolean;
};

const LatestContent: React.FC<LatestContentProps> = props => {
  const locale = useLocale();

  const articles = props.articles.map(article => (
    <LatestContentStyles.ArticleWidth key={article.title}>
      <ArticleTeaser article={article} />
    </LatestContentStyles.ArticleWidth>
  ));

  return (
    <LatestContentStyles.Container>
      <Container tocGutter={props.tocGutter}>
        <LatestContentStyles.TitleSpacing>
          <Flex alignItems="center" justifyContent="space-between" gap>
            <LatestContentStyles.Title>
              <Text.H4Responsive>
                <LocalizedText
                  id={props.hideDashboardLatestContent ? 'markets.latestContent' : 'dashboard.latestContent'}
                  description="Latest content section title"
                />
              </Text.H4Responsive>
            </LatestContentStyles.Title>
            <ArrowLink
              href={`/${locale}/articles${props.articlesSlug ? `/${props.articlesSlug}` : ''}`}
              labelId="contentArticlesLink"
              description="Read more articles link"
            />
          </Flex>
        </LatestContentStyles.TitleSpacing>
      </Container>

      {/* If there's an error, display it */}
      {props.error ? (
        // If there's no error and it's not loading and it does have articles
        <Container tocGutter={props.tocGutter}>
          <BlankState icon={<img src="/static/icons/listLight.svg" />}>
            <LocalizedText
              id={props.hideDashboardLatestContent ? 'blankState.markets.articles' : 'blankState.dashboard.articles'}
              description="Latest content"
            />
          </BlankState>
        </Container>
      ) : props.loading ? (
        <>
          {/* If there's no error and it's loading */}
          <Breakpoints.LaptopAndUp>
            <Container tocGutter={props.tocGutter}>
              <Grid>
                {/* Create 3 rows of similar fake loaders */}
                {Array(3)
                  .fill(3)
                  .map((_, index) => (
                    <ArticleTeaser key={index} loading />
                  ))}
              </Grid>
            </Container>
          </Breakpoints.LaptopAndUp>
          <Breakpoints.TabletAndDown>
            <HorizontalScroll hideArrowsMobile scrollContentTravelDistance={420}>
              <ContainerPadding tocGutter={props.tocGutter}>
                {/* Create 3 rows of similar fake loaders */}
                {Array(3)
                  .fill(3)
                  .map((_, index) => (
                    <LatestContentStyles.ArticleWidth key={index}>
                      <ArticleTeaser loading />
                    </LatestContentStyles.ArticleWidth>
                  ))}
              </ContainerPadding>
            </HorizontalScroll>
          </Breakpoints.TabletAndDown>
        </>
      ) : (
        props.articles &&
        props.articles.length > 0 && (
          <>
            {/* If there's no error and it's not loading and it does have articles */}
            {/* I'm using breakpoints here because the desktop version need to have a container */}
            <Breakpoints.LaptopAndUp>
              <Container tocGutter={props.tocGutter}>
                <HorizontalScroll hideArrowsMobile calculateHeight scrollContentTravelDistance={420}>
                  {articles}
                </HorizontalScroll>
              </Container>
            </Breakpoints.LaptopAndUp>
            {/* Mobile */}
            <Breakpoints.TabletAndDown>
              <HorizontalScroll hideArrowsMobile calculateHeight scrollContentTravelDistance={420}>
                <ContainerPadding tocGutter={props.tocGutter}>{articles}</ContainerPadding>
              </HorizontalScroll>
            </Breakpoints.TabletAndDown>
          </>
        )
      )}
    </LatestContentStyles.Container>
  );
};

export default LatestContent;
